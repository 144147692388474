import { IAddress } from '../interfaces/miscellaneous.interface';

export const emailReg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const phoneReg = /^\d{10}$/;
export const landlineReg = /^((?:\+27|27)|0)(\d{9})$/;
export const idReg = /^(((\d{2}((0[13578]|1[02])(0[1-9]|[12]\d|3[01])|(0[13456789]|1[012])(0[1-9]|[12]\d|30)|02(0[1-9]|1\d|2[0-8])))|([02468][048]|[13579][26])0229))(( |-)(\d{4})( |-)(\d{3})|(\d{7}))/;

export function toLowercase(text: string): string | null {
  if (typeof text === 'string' && text.trim().length) {
    return text.trim().replace(/\s{2,}/g, ' ').toLowerCase();
  } else {
    return null;
  }
}

export function toUppercase(text: string): string | null {
  if (typeof text === 'string' && text.trim().length) {
    return text.trim().replace(/\s{2,}/g, ' ').toUpperCase();
  } else {
    return null;
  }
}

export function toStartcase(text: string): string | null {
  if (typeof text === 'string' && text.trim().length) {
    const lowers = ['A', 'An', 'The', 'And', 'But', 'Or', 'For', 'Nor', 'As', 'At', 'By', 'For', 'From', 'In', 'Into', 'Near', 'Of', 'On', 'Onto', 'To', 'With'];
    const uppers = ['Id', 'Uid', 'Tv'];
    let i, j, str;
    str = text.trim().replace(/\s{2,}/g, ' ').replace(/([^\W_]+[^\s-]*) */g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });

    for (i = 0, j = lowers.length; i < j; i++)
      str = str.replace(
        new RegExp('\\s' + lowers[i] + '\\s', 'g'),
        (txt) => { return txt.toLowerCase(); }
      );

    for (i = 0, j = uppers.length; i < j; i++)
      str = str.replace(
        new RegExp('\\b' + uppers[i] + '\\b', 'g'),
        uppers[i].toUpperCase()
      );

    return str;
  } else {
    return null;
  }
}

export function toHandle(text: string): string | null {
  return text ? text.toString().toLowerCase()
    .replace(/\s+/g, '-')           // Replace spaces with -
    .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
    .replace(/\-\-+/g, '-')         // Replace multiple - with single -
    .replace(/^-+/, '')             // Trim - from start of text
    .replace(/-+$/, '') : null;            // Trim - from end of text
}

export function validateEmail(email: any): boolean {
  return typeof email === 'string' && email.length ? emailReg.test(email) : false;
}

export function validatePhone(phone: any): boolean {
  return typeof phone === 'string' && phone.length ? phoneReg.test(phone) : false;
}

export function validateIdNumber(id: any): boolean {
  return typeof id === 'string' && id.length ? idReg.test(id) : false;
}

// Removes properties from an object where values are either null, undefined, or empty string
export function reduceObject(obj: object | any, exceptions?: string[]): object | null {
  const o = JSON.parse(JSON.stringify(obj));
  const lightweight = Object.keys(o).reduce((prev: { [key: string]: any; }, curr) => {
    if (
      (exceptions && exceptions.includes(curr)) ||
      (o[curr] !== null && o[curr] !== undefined) ||
      (typeof o[curr] === 'string' && o[curr].length > 0)

    ) {
      prev[curr] = o[curr];
    }
    return prev;
  }, {});

  return lightweight.constructor === Object && Object.keys(lightweight).length > 0 ? lightweight : null;
}

export function addressToString(address: IAddress): string {
  let addressString = '';

  if (address) {
    const { lineOne, lineTwo, city, province, code } = address;

    addressString += lineOne ? address.lineOne + (lineTwo || city || province || code ? ', ' : '') : '';

    addressString += lineTwo ? lineTwo + (city || province || code ? ', ' : '') : '';
    addressString += city ? city + (province || code ? ', ' : '') : '';
    addressString += province ? province + (code ? ', ' : '') : '';
    addressString += code ? code : '';
  }

  return addressString;
}

export function formatPhoneNumber(text: string): string | null {
  if (text === undefined || text === null || !text.toString().length) { return null; }

  if (
    text.length === 10 &&
    text.charAt(0) === '0' &&
    (+text.charAt(1)) >= (+'6')
  ) {
    return `27${text.substring(1)}`;
  }

  if (
    text.length === 12 &&
    text.charAt(0) === '+'
  ) {
    return `27${text.substring(3)}`;
  }

  return text;
}
