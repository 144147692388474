import { Platform } from '@angular/cdk/platform';
import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { IUser } from '@core/models/user.model';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { Navigate } from '@ngxs/router-plugin';
import { Select, Store } from '@ngxs/store';
import { locale as navigationEnglish } from 'app/navigation/i18n/en';
import { locale as navigationTurkish } from 'app/navigation/i18n/tr';
import { navigation } from 'app/navigation/navigation';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  fuseConfig: any;
  navigation: any;

  @Select((s) => s.user) user$: Observable<IUser>;

  private _unsubscribeAll: Subject<any>;

  permissions = [
    'recruiter_dashboard_view_dashboard',
    'candidate_create_edit',
    'vacancy_create_edit',
    'job_profile_create_edit',
    'user_create_edit',
    'notifications',
    'umalusi',
    'client_create_edit',
    'company_edit',
    'vacancy_view',
    'simplify_hr',
    'farosian'
  ];

  constructor(
    @Inject(DOCUMENT) private document: any,
    private _fuseConfigService: FuseConfigService,
    private _fuseNavigationService: FuseNavigationService,
    private _fuseSidebarService: FuseSidebarService,
    private _fuseSplashScreenService: FuseSplashScreenService,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private _translateService: TranslateService,
    private _platform: Platform,
    private afs: AngularFirestore,
    private store: Store,
    private snackbar: MatSnackBar,
    private router: Router
  ) {

    this.user$.subscribe(async (user: IUser) => {
      if (!user) { return; }

      this.navigation = navigation;

      const reads = [];
      this.permissions.forEach((permission: string) => {
        reads.push(this.afs.doc(`users/${user.uid}/permissions/${permission}`).ref.get());
      });

      const results = await Promise.all(reads);

      const allows = results.map((r) => ({ key: r.id, allowed: r.exists })).filter((r) => r.allowed);

      if (allows.length === 0) {
        if (!['/otp', '/forgot-password'].some((exceptionRoute) => exceptionRoute === this.router.url)) {
          this.store.dispatch(new Navigate(['sign-in']));
          this.snackbar.open('You do not have enough permissions to view any pages or please sign in');
        }
      }

      const allowedNav = [];
      navigation.forEach((i) => {
        if (i.id === 'dashboard-recruiter' && !user.companyUnderReview) {
          if (allows.some((a) => a.key === 'recruiter_dashboard_view_dashboard')) {
            allowedNav.push(i);
          }
        } else if (i.id === 'dashboard-recruiter-v2' && !user.companyUnderReview) {
          if (allows.some((a) => a.key === 'recruiter_dashboard_view_dashboard')) {
            allowedNav.push(i);
          }
        } else if (i.id === 'recruiter' && !user.companyUnderReview) {
          if (
            allows.some((a) => a.key === 'candidate_create_edit') ||
            allows.some((a) => a.key === 'vacancy_create_edit') ||
            allows.some((a) => a.key === 'vacancy_view')
          ) {
            allowedNav.push({ ...i, children: [] });
            if (allows.some((a) => a.key === 'candidate_create_edit')) {
              allowedNav[allowedNav.length - 1].children.push(i.children[0]);
            }

            if (allows.some((a) => a.key === 'vacancy_create_edit') || allows.some((a) => a.key === 'vacancy_view')) {
              allowedNav[allowedNav.length - 1].children.push(i.children[1]);
            }

            if (allows.some((a) => a.key === 'candidate_create_edit') && allows.some((a) => a.key === 'vacancy_create_edit')) {
              allowedNav[allowedNav.length - 1].children.push(i.children[2]);
            }

            if (allows.some((a) => a.key === 'candidate_create_edit') && allows.some((a) => a.key === 'vacancy_create_edit')) {
              allowedNav[allowedNav.length - 1].children.push(i.children[3]);
            }
            
            if (allows.some((a) => a.key === 'simplify_hr')) {
              allowedNav[allowedNav.length - 1].children.push(i.children[4]);
            }
          }
        } else if (i.id === 'administrator') {
          if (
            allows.some((a) => a.key === 'job_profile_create_edit') ||
            allows.some((a) => a.key === 'vacancy_create_edit') ||
            (user.company.uid === 'OwQp35TST6dyFjUk19ml' && allows.some((a) => a.key === 'client_create_edit')) ||
            allows.some((a) => a.key === 'notifications') ||
            allows.some((a) => a.key === 'umalusi') ||
            allows.some((a) => a.key === 'client_create_edit') ||
            allows.some((a) => a.key === 'user_create_edit')
          ) {
            allowedNav.push({ ...i, children: [] });
            if (allows.some((a) => a.key === 'job_profile_create_edit') && !user.companyUnderReview) {
              allowedNav[allowedNav.length - 1].children.push(i.children[0]);
            }
            if (user.company.uid === 'OwQp35TST6dyFjUk19ml' && allows.some((a) => a.key === 'client_create_edit') && !user.companyUnderReview) {
              allowedNav[allowedNav.length - 1].children.push(i.children[1]);
              allowedNav[allowedNav.length - 1].children.push(i.children[7]);
            }
            if (allows.some((a) => a.key === 'user_create_edit')) {
              allowedNav[allowedNav.length - 1].children.push(i.children[2]);
            }
            if (allows.some((a) => a.key === 'notifications') && !user.companyUnderReview) {
              allowedNav[allowedNav.length - 1].children.push(i.children[3]);
            }
            if (allows.some((a) => a.key === 'umalusi') && !user.companyUnderReview) {
              allowedNav[allowedNav.length - 1].children.push(i.children[4]);
            }
            if (allows.some((a) => a.key === 'farosian')) {
              allowedNav[allowedNav.length - 1].children.push(i.children[5]);
            }
            if (allows.some((a) => a.key === 'company_edit')) {
              allowedNav[allowedNav.length - 1].children.push(i.children[6]);
            }
          }
        }
      });
      
      this._fuseNavigationService.unregister('main');
      // Register the navigation to the service
      this._fuseNavigationService.register('main', allowedNav);

      // Set the main navigation as our current navigation
      this._fuseNavigationService.setCurrentNavigation('main');
    });

    // Add languages
    this._translateService.addLangs(['en', 'tr']);

    // Set the default language
    this._translateService.setDefaultLang('en');

    // Set the navigation translations
    this._fuseTranslationLoaderService.loadTranslations(navigationEnglish, navigationTurkish);

    // Use a language
    this._translateService.use('en');

    /**
     * ----------------------------------------------------------------------------------------------------
     * ngxTranslate Fix Start
     * ----------------------------------------------------------------------------------------------------
     */

    /**
     * If you are using a language other than the default one, i.e. Turkish in this case,
     * you may encounter an issue where some of the components are not actually being
     * translated when your app first initialized.
     *
     * This is related to ngxTranslate module and below there is a temporary fix while we
     * are moving the multi language implementation over to the Angular's core language
     * service.
     **/

    // Set the default language to 'en' and then back to 'tr'.
    // '.use' cannot be used here as ngxTranslate won't switch to a language that's already
    // been selected and there is no way to force it, so we overcome the issue by switching
    // the default language back and forth.
    /**
     setTimeout(() => {
        this._translateService.setDefaultLang('en');
        this._translateService.setDefaultLang('tr');
     });
     */

    /**
     * ----------------------------------------------------------------------------------------------------
     * ngxTranslate Fix End
     * ----------------------------------------------------------------------------------------------------
     */

    // Add is-mobile class to the body if the platform is mobile
    if (this._platform.ANDROID || this._platform.IOS) {
      this.document.body.classList.add('is-mobile');
    }

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to config changes
    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this.fuseConfig = config;

        // Boxed
        if (this.fuseConfig.layout.width === 'boxed') {
          this.document.body.classList.add('boxed');
        }
        else {
          this.document.body.classList.remove('boxed');
        }

        // Color theme - Use normal for loop for IE11 compatibility
        for (let i = 0; i < this.document.body.classList.length; i++) {
          const className = this.document.body.classList[i];

          if (className.startsWith('theme-')) {
            this.document.body.classList.remove(className);
          }
        }

        this.document.body.classList.add(this.fuseConfig.colorTheme);
      });

  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(undefined);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebarOpen(key): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }
}
