import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';


const routes: Routes = [{
    path: 'dashboard/recruiter-v2',
    loadChildren: () => import('./main/dashboards/recruiter-dashboard-v2/recruiter-dashboard-v2.module')
        .then((m) => m.RecruiterDashboardV2Module)
},

    {
        path: 'candidates',
        loadChildren: () => import('./main/recruit/candidates/candidates.module')
            .then((m) => m.CandidatesModule)
    }, {
        path: 'candidates/:candidate',
        loadChildren: () => import('./main/recruit/candidates/children/candidate/candidate.module')
            .then((m) => m.CandidateModule)
    },

    {
        path: 'candidate-chk/:candidate',
        loadChildren: () => import('./main/recruit/checks/children/candidate-chk/candidate-chk.module')
            .then((m) => m.CandidateChkModule)
    },
    /*{
        path: 'candidate-checks',
        loadChildren: () => import('./main/recruit/checks/children/candidate-chk/children/candidate-checks/candidate-checks.module')
            .then((m) => m.CandidateChecksModule)
    },*/

    {
        path: 'job-profiles',
        loadChildren: () => import('./main/administer/job-profiles/job-profiles.module')
            .then((m) => m.JobProfilesModule)
    }, {
        path: 'job-profiles/:job-profile',
        loadChildren: () => import('./main/administer/job-profiles/children/job-profile/job-profile.module')
            .then((m) => m.JobProfileModule)
    }, {
        path: 'vacancies',
        loadChildren: () => import('./main/recruit/vacancies/vacancies.module')
            .then((m) => m.VacanciesModule)
    }, {
        path: 'checks',
        loadChildren: () => import('@main/recruit/checks/checks-candidate.module')
            .then((m) => m.ChecksCandidateModule)
    }, {
        path: 'vacancies/:vacancy',
        loadChildren: () => import('./main/recruit/vacancies/children/vacancy/vacancy.module')
            .then((m) => m.VacancyModule)
    }, {
        path: 'reruns',
        loadChildren: () => import('./main/recruit/reruns/reruns.module')
            .then((m) => m.RerunsModule)
    }, {
        path: 'clients',
        loadChildren: () => import('./main/administer/clients/clients.module')
            .then((m) => m.ClientsModule)
    },
    {
        path: 'integration',
        loadChildren: () => import('./main/administer/integration-settings/integration-settings.module')
            .then((m) => m.IntegrationSettingsModule)
    }, {
        path: 'clients/:client',
        loadChildren: () => import('./main/administer/clients/children/client/client.module')
            .then((m) => m.ClientModule)
    }, {
        path: 'settings/:client',
        loadChildren: () => import('./main/administer/clients/children/client/client.module')
            .then((m) => m.ClientModule)
    }, {
        path: 'users',
        loadChildren: () => import('./main/administer/users/users.module')
            .then((m) => m.UsersModule)
    }, {
        path: 'users/:user',
        loadChildren: () => import('./main/administer/users/children/user/user.module')
            .then((m) => m.UserModule)
    }, {
        path: 'consent/:consent',
        loadChildren: () => import('./main/recruit/candidates/children/consent/candidate-consent.module')
            .then((m) => m.CandidateConsentModule)
    },
// {
//   path: 'users/add-user',
//   loadChildren: () => import('./main/administer/users/children/add-user/add-user.module')
//     .then((m) => m.AcademicModule)
// },
// {
//   path: 'users/:user',
//   loadChildren: () => import('./main/administer/users/children/user/user.module')
//     .then((m) => m.SubjectModule)
// },

    {
        path: 'settings',
        loadChildren: () => import('./main/administer/settings/settings.module')
            .then((m) => m.SettingsModule)
    },

    {
        path: 'registration',
        loadChildren: () => import('./pages/registration/registration.module')
            .then((m) => m.RegistrationModule)
    },

    {
        path: 'notifications',
        loadChildren: () => import('./main/administer/notifications/notifications.module')
            .then((m) => m.NotificationsModule)
    },

    {
        path: 'umalusi',
        loadChildren: () => import('./main/administer/umalusi/umalusi.module')
            .then((m) => m.UmalusiModule)
    },
    {
        path: 'sign-in',
        loadChildren: () => import('./pages/auth/sign-in/sign-in.module')
            .then((m) => m.SignInModule)
    }, {
        path: 'forgot-password',
        loadChildren: () => import('./pages/auth/forgot-password/forgot-password.module')
            .then((m) => m.ForgotPasswordModule)
    }, {
        path: 'otp',
        loadChildren: () => import('./pages/auth/otp/otp.module')
            .then((m) => m.OtpModule)
    }, {
        path: 'reset-password',
        loadChildren: () => import('./pages/auth/reset-password/reset-password.module')
            .then((m) => m.ResetPasswordModule)
    }, {
        path: 'user-disclaimer',
        loadChildren: () => import('./pages/auth/disclaimer/user-disclaimer.module')
            .then((m) => m.UserDisclaimerModule)
    },
    {
        path: 'simplify-hr',
        loadChildren: () => import('./main/administer/simplify-hr/simplify-hr.module')
            .then((m) => m.SimplifyHRModule)
    },
    {
        path: 'farosian',
        loadChildren: () => import('./main/administer/farosian/farosian.module')
            .then((m) => m.FarosianModule)
    },

    {
        path: 'farosian/add-subject',
        loadChildren: () => import('./main/administer/farosian/children/add-subject/add-subject.module')
            .then((m) => m.AddSubjectModule)
    },
    {
        path: 'farosian/add-subject/:subject',
        loadChildren: () => import('./main/administer/farosian/children/add-subject/add-subject.module')
            .then((m) => m.AddSubjectModule)
    }, {
        path: 'farosian/subject',
        loadChildren: () => import('./main/administer/farosian/children/subject/subject.module')
            .then((m) => m.SubjectModule)
    }, {
        path: 'farosian/subject/:subject',
        loadChildren: () => import('./main/administer/farosian/children/subject/subject.module')
            .then((m) => m.SubjectModule)
    },

    {
        path: '**',
        redirectTo: 'sign-in',
        pathMatch: 'full'
    },
    // {
    //   path: '404',
    //   loadChildren: () => import('./pages/errors/error-404/error-404.module')
    //     .then((m) => m.Error404Module)
    // }, {
    //   path: '**',
    //   redirectTo: '404',
    //   pathMatch: 'full'
    // }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}

