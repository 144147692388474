import { INameUid } from '@clickncheck/shared/src';

export class SetVacancy {
  static readonly type = '[CONTEXT] set vacancy';
  constructor(
    public payload: { name: string, uid: string, referenceNumber: string, origin: string }
  ) { }
}

export class ResetContext {
  static readonly type = '[CONTEXT] reset context';
  constructor() { }
}
