import { Action, State, StateContext } from '@ngxs/store';
import { SetUser, ResetUser } from './user-store.actions';
import { IUserStore } from './user-store.model';

@State<IUserStore>({
  name: 'user',
  defaults: null
})
export class UserStore {

  @Action(SetUser)
  setUser(
    { setState }: StateContext<IUserStore>,
    { payload }: SetUser
  ): void {
    setState(payload);
  }

  @Action(ResetUser)
  resetUser(
    { setState }: StateContext<IUserStore>
  ): void {
    setState(null);
  }
}
