import { Action, State, StateContext } from '@ngxs/store';

import { ResetContext, SetVacancy } from './context-store.actions';
import { IContextStore } from './context-store.model';

const DEFAULTS = {
  vacancy: null,
};

@State<IContextStore>({
  name: 'context',
  defaults: DEFAULTS
})
export class ContextStore {

  @Action(SetVacancy)
  setVacancy(
    { patchState }: StateContext<IContextStore>,
    { payload }: SetVacancy
  ): void {
    patchState({ vacancy: payload });
  }

  @Action(ResetContext)
  resetContext(
    { patchState }: StateContext<IContextStore>
  ): void {
    patchState(DEFAULTS);
  }
}
